import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBTPFTuntDghKG5kUZqIsmk3gqScd1WC08",
  authDomain: "eo-race.firebaseapp.com",
  databaseURL: "https://eo-race.firebaseio.com",
  projectId: "eo-race",
  storageBucket: "eo-race.appspot.com",
  messagingSenderId: "1009552214559",
  appId: "1:1009552214559:web:e19618eb46e0e17d29632a",
  measurementId: "G-0YXWD81289",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export const storage = firebase.storage();
export default db;

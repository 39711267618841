import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Home from "./Home/Home";
import Games from "./Games/Games";
import EndPage from "./EndPage/EndPage";
import StartScreen from "./StartScreen";
import { url } from "./url";

function App() {
  const [data, setData] = useState({});

  let { sessionId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const response = await axios(`${url}/api/data/${sessionId}`);
      setData(response.data);
    }
    fetchData();
  }, [sessionId]);

  return (
    <Router>
      <Switch>
        <Route exact path="/:sessionId">
          <Home data={data} />
        </Route>
        <Route exact path="/:sessionId/start">
          <StartScreen data={data} />
        </Route>
        <Route path="/:sessionId/games">
          <Games data={data} />
        </Route>
        <Route path="/:sessionId/end">
          <EndPage data={data} />
        </Route>
        {/* <Route path="/:sessionId/test">
          <PicScreen /> */}
        {/* <AudioScreen /> */}
        {/* <VidScreen /> */}
        {/* </Route> */}
      </Switch>
    </Router>
  );
}

export default App;

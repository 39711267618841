import React, { useState, useEffect } from "react";
import GoogleFontLoader from "react-google-font-loader";
import { useHistory, useParams } from "react-router-dom";
//antd styling
import { Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { url } from "../url";

//css
import "./Home.css";
import axios from "axios";

const Home = (props) => {
  let { sessionId } = useParams();
  const history = useHistory();
  const { data } = props;
  useEffect(() => {
    document.title = sessionId;
  }, []);

  const [teamName, setTeamName] = useState("");

  const handleSubmit = (teamName) => {
    localStorage.setItem("teamName", teamName.toLowerCase());
    localStorage.setItem("sessionId", sessionId);

    const dataToSend = {
      name: teamName.toLowerCase(),
      sessionId: sessionId,
    };

    axios
      .post(`${url}/api/login/${sessionId}`, dataToSend)
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error);
        } else {
          history.push(`/${sessionId}/start`);
        }
      })
      .catch((err) => alert(err));
  };

  return Object.keys(data).length > 1 ? (
    <div
      className='home-container'
      style={{
        backgroundImage: `url('${data.loginBackground}')`,
        backgroundSize: "cover",
      }}
    >
      <GoogleFontLoader
        fonts={[
          {
            font: `${data.btnFont}`,
            weights: [400, "400i"],
          },
        ]}
      />
      <div className='home'>
        <div className='heading-image'>
          <img src={`${data.loginCreative}`} alt='headingImage' />
        </div>
        <div className='home-input-container'>
          <Input
            placeholder='Enter Login ID'
            prefix={<UserOutlined />}
            onChange={(e) => setTeamName(e.target.value)}
            className='home-input'
            style={{ width: "60%" }}
          />

          <Button
            variant='contained'
            color='primary'
            onClick={() => handleSubmit(teamName)}
            style={{
              fontFamily: `${data.btnFont}`,
              backgroundColor: `${data.btnColor}`,
              border: `1px solid ${data.btnHilight}`,
              color: `${data.btnHilight}`,
            }}
          >
            Start
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Home;

import React, { useState, useMemo, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";

import GoogleFontLoader from "react-google-font-loader";
import { Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
//games
import ImagePuzzle from "../GameComponents/ImagePuzzle/ImagePuzzle";
import Quiz from "../GameComponents/Quiz/Quiz";
import Greeting from "../GameComponents/Greeting/Greeting";
import ReactHowler from "react-howler";
import Crossword from "../GameComponents/Crossword/Crossword";

import useSound from "use-sound";
import Buzzer from "../buzzer.mp3";

import "./Games.css";
import ClueGame from "../GameComponents/ClueGame/ClueGame";
import UploadPic from "../GameComponents/UploadPic/UploadPic";
import UploadPic2 from "../GameComponents/UploadPic2/UploadPic2";

import PicScreen from "../GameComponents/picScreen/PicScreen";
import AudioScreen from "../GameComponents/audioScreen/AudioScreen";
import VidScreen from "../GameComponents/vidScreen/VidScreen";
import db from "../firebase";
import resLoader from "../resLoader.gif";
import { url } from "../url";
import axios from "axios";

const { confirm } = Modal;

const MainGame = (props) => {
  let { sessionId } = useParams();
  const teamName = localStorage.getItem("teamName");
  console.log(teamName);
  const [totalScore, setTotalScore] = useState(0);
  const { data } = props;
  const [playSound, setPlaySound] = useState(true);

  const [isCreative, setIsCreative] = useState(false);
  const [currentGameId, setCurrentGameId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const childRef = useRef();
  useEffect(() => {
    db.collection("sessions")
      .doc(sessionId)
      .collection("teams")
      .doc(teamName)
      .onSnapshot((snap) => {
        const d = snap.data();
        setCurrentGameId(d.currentLevel);
        setTotalScore(d.score);
      });
  }, []);
  useEffect(() => {
    db.collection("sessions")
      .doc(sessionId)
      .onSnapshot((snap) => {
        const d = snap.data();
        if (!d.active) {
          props.history.push(`/${sessionId}/end`);
        }
      });
  }, []);

  const checkFinish = async () => {
    const dataToSend = {
      name: localStorage.getItem("teamName"),
      sessionId: localStorage.getItem("sessionId"),
      game_type: "creative_page",
      game_id: games[currentGameId]?.props?.data?.id,
    };
    setShowLoader(true);
    await axios
      .post(`${url}/api/score/${sessionId}`, dataToSend)
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          alert(res.data.error);
        }
      })
      .catch((err) => alert(err));
    setShowLoader(false);
  };
  const [buzz] = useSound(Buzzer, { volume: 0.25 });

  const showSkipConfirm = () => {
    confirm({
      title:
        "Are you sure you want to directly move ahead? You will lose some points.",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        childRef.current.skipLevel();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onGameFinish = (someData) => {};

  const createGamesList = () => {
    let g = [];

    for (let i = 0; i < data.gamesData.length; i++) {
      switch (data.gamesData[i].type) {
        case "image_puzzle":
          g.push(
            <ImagePuzzle
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              logoImg={data.logoImg}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;
        case "clue_page":
          g.push(
            <ClueGame
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              logoImg={data.logoImg}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;
        case "image_clue":
          g.push(
            <PicScreen
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              logoImg={data.logoImg}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;
        case "audio_clue":
          g.push(
            <AudioScreen
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              logoImg={data.logoImg}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;
        case "video_clue":
          g.push(
            <VidScreen
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              logoImg={data.logoImg}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;

        case "upload_page":
          g.push(
            <UploadPic
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;
        case "upload_page_2":
          g.push(
            <UploadPic2
              ref={childRef}
              data={data.gamesData[i]}
              loader={data.loadingSpinner}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );
          break;

        case "image_quiz":
          g.push(
            <Quiz
              ref={childRef}
              data={data.gamesData[i]}
              logoImg={data.logoImg}
              loader={data.loadingSpinner}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              gameFinish={onGameFinish}
            />
          );

          break;

        case "crossword":
          g.push(
            <Crossword
              data={data.gamesData[i]}
              logoImg={data.logoImg}
              btnColor={data.btnColor}
              btnHilight={data.btnHilight}
              loader={data.loadingSpinner}
              gameFinish={onGameFinish}
              ref={childRef}
            />
          );

          break;

        case "creative_page":
          g.push(
            <Greeting
              data={data.gamesData[i]}
              logoImg={data.logoImg}
              gameFinish={onGameFinish}
              loader={data.loadingSpinner}
            />
          );

          break;
        default:
          break;
      }
    }
    return g;
  };
  // eslint-disable-next-line
  const games = useMemo(() => createGamesList(), []);

  useEffect(() => {
    if (games[currentGameId]?.props?.data?.type === "creative_page") {
      setIsCreative(true);
      if (games[currentGameId]?.props?.data?.primaryMediaType === "yt")
        setPlaySound(false);
      else setPlaySound(true);
    } else {
      setIsCreative(false);
    }
    if (games[currentGameId]?.props?.data?.type === "image_clue") {
      setPlaySound(false);
    }
    if (games[currentGameId]?.props?.data?.type === "audio_clue") {
      setPlaySound(false);
    }
    if (games[currentGameId]?.props?.data?.type === "video_clue") {
      setPlaySound(false);
    }
  }, [currentGameId, games]);

  const renderGames = () => {
    if (currentGameId === games.length) props.history.push(`/${sessionId}/end`);
    else {
      return games[currentGameId];
    }
  };

  if (currentGameId === null) {
    return <h1>Loading</h1>;
  }
  return (
    <div className="main-game-conatiner" style={{ height: "90vh" }}>
      <ReactHowler
        src={data.gameSound}
        playing={playSound}
        loop={true}
        preload={true}
      />

      <GoogleFontLoader
        fonts={[
          {
            font: `${data.btnFont}`,
            weights: [400, "400i"],
          },
        ]}
      />
      {showLoader ? (
        <img
          src={resLoader}
          style={{ position: "fixed", top: "45vh", left: "45vw" }}
          alt="img"
        />
      ) : (
        renderGames()
      )}
      {!isCreative ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              position: "fixed",
              bottom: "3vh",
              left: "5vw",
              borderRadius: "10px",
              fontWeight: "600",
              padding: "0.5rem",
              fontFamily: `${data.btnFont}`,
              backgroundColor: `${data.btnColor}`,
              textAlign: "center",
              color: `${data.btnHilight}`,
            }}
            // onClick={checkFinish}
          >
            Score : {totalScore}
          </span>
          <Button
            style={{
              position: "fixed",
              bottom: "3vh",
              right: "5vw",
              borderRadius: "10px",
              fontWeight: "600",
              fontFamily: `${data.btnFont}`,
              backgroundColor: `${data.btnColor}`,
              border: `1px solid ${data.btnHilight}`,
              color: `${data.btnHilight}`,
            }}
            onClick={showSkipConfirm}
          >
            Skip
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            style={{
              position: "fixed",
              bottom: "3vh",
              left: "43vw",
              borderRadius: "10px",
              fontWeight: "600",
              fontFamily: `${data.btnFont}`,
              backgroundColor: `${data.btnColor}`,
              border: `1px solid ${data.btnHilight}`,
              color: `${data.btnHilight}`,
            }}
            onClick={checkFinish}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default withRouter(MainGame);

import React, { useState, useEffect } from "react";
import TopImage from "../../Components/TopImage/TopImage";
import YtVideo from "../../Components/YtVideo/YtVideo";
import "./Greeting.css";

const Greeting = ({ data, gameFinish, logoImg, loader }) => {
  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setShowLoading(false), 2000);
  }, []);
  return (
    <div
      className="greeting-container"
      style={{
        backgroundImage: `url('${data.bgImg}')`,
        backgroundSize: "cover",
      }}
    >
      {!showLoading ? (
        <>
          <TopImage imageUrl={data.titleImg} />
          <YtVideo
            mediaType={data.primaryMediaType}
            url={data.primaryMediaUrl}
          />
          {data.secondaryImg.length > 1 ? (
            <img
              src={data.secondaryImg}
              alt=""
              id="secondaryImg"
              style={{ width: "90%", height: "auto" }}
              onError={() => {
                let a = document.querySelector("#secondaryImg");
                a.style.height = 0;
                a.style.width = 0;
              }}
            />
          ) : null}

          {gameFinish()}
        </>
      ) : (
        <div
          style={{ display: "grid", placeItems: "center", paddingTop: "40vh" }}
        >
          <img src={loader} alt="loading..." />
        </div>
      )}
    </div>
  );
};

export default Greeting;

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Puzzle from "./components/Puzzle";
import TopImage from "../../Components/TopImage/TopImage";
import useSound from "use-sound";
import Win from "../../win.mp3";
import Reward from "react-rewards";
import "./ImagePuzzle.css";
import QuestionHintButton from "../../Components/HintButtons/QuestionHintButton";
import Timer from "../../Components/Timer";
import axios from "axios";
import { useParams } from "react-router-dom";
import resLoader from "../../resLoader.gif";
import { url } from "../../url";

const ImagePuzzle = forwardRef(
  ({ data, btnColor, btnHilight, gameFinish, stuck, loader }, ref) => {
    const reward = useRef();
    const deadline = data.timer;
    let { sessionId } = useParams();
    const confetti = {
      type: "confetti",
      fakingRequest: false,
      angle: 90,
      decay: 0.91,
      spread: 45,
      startVelocity: 35,
      elementCount: 300,
      elementSize: 20,
      lifetime: 200,
      zIndex: 100,
      springAnimation: true,
    };

    const baseScore = data.baseScore;
    const [startTimer, setStartTimer] = useState(true);
    const [timeLeft, setTimeLeft] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [win] = useSound(Win, { volume: 0.25 });
    const [skipped, setSkipped] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
      setTimeout(() => setShowLoading(false), 4000);
    }, []);

    useImperativeHandle(ref, () => ({
      skipLevel() {
        setSkipped(true);

        setTimeout(() => {
          getReward();
          setShowImage(true);
        }, 500);

        // gameFinish();
      },
    }));

    const getReward = () => {
      reward.current.rewardMe();
      win();
      // setStartTimer(false);
      setTimeout(() => setStartTimer(false), 4000);
    };

    const getTimeLeft = async (sec) => {
      const score = parseInt(baseScore) + parseInt(data.multiplier) * sec;
      const dataToSend = {
        currentTime: sec,
        name: localStorage.getItem("teamName"),
        sessionId: localStorage.getItem("sessionId"),
        skipped: skipped,
        game_type: data.type,
        score: parseInt(score),
      };

      setShowLoader(true);
      await axios
        .post(`${url}/api/score/${sessionId}`, dataToSend)
        .then((res) => {
          console.log(res.data);
          if (res.data.error) {
            alert(res.data.error);
          }
        })
        .catch((err) => alert(err));
      setShowLoader(false);
      // gameFinish(dataToSend);
      setTimeLeft(sec);
    };
    const handleTimeFinish = (timeRemaining) => {
      console.log("finished in", timeRemaining);
    };

    return (
      <div
        style={{
          backgroundImage: `url('${data.bgImg}')`,
        }}
        className="main-image-puzzle-container"
      >
        {!stuck || !showLoading ? (
          <div className="image-puzzle-container">
            <QuestionHintButton
              btnColor={btnColor}
              btnHilight={btnHilight}
              hintImg={data.hintImg}
            />
            <TopImage imageUrl={data.titleImg} />
            {showLoader ? (
              <img
                src={resLoader}
                style={{ position: "fixed", top: "45vh", left: "45vw" }}
              />
            ) : null}

            <Reward
              ref={(ref) => (reward.current = ref)}
              type={data.rewardType}
              config={{ ...confetti, emoji: data.emojiList }}
            >
              <div
                className="puzzle-container"
                style={{
                  backgroundImage: `url(${data.borderImg})`,
                }}
              >
                {!showImage ? (
                  <Puzzle
                    image={data.img}
                    level={data.sectionCount}
                    size={321}
                    ref={reward}
                    onDone={() => {
                      setShowImage(true);
                      win();
                      getReward();
                    }}
                  />
                ) : (
                  <img
                    src={data.img}
                    alt="puzzle-img"
                    style={{ width: "321px", height: "321px", zIndex: "10" }}
                  />
                )}
              </div>
            </Reward>

            <Timer
              time={deadline}
              handleFinish={handleTimeFinish}
              start={startTimer}
              getTimeLeft={getTimeLeft}
            />
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              paddingTop: "40vh",
            }}
          >
            <img src={loader} alt="loading..." />
          </div>
        )}
      </div>
    );
  }
);

export default ImagePuzzle;

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import QuestionHintButton from "../../Components/HintButtons/QuestionHintButton";
import CrosswordGame, { ThemeProvider } from "@jaredreisinger/react-crossword";
import "./Crossword.css";
import useSound from "use-sound";
import Win from "../../win.mp3";
import Reward from "react-rewards";
import Timer from "../../Components/Timer";
import { url } from "../../url";
import axios from "axios";
import resLoader from "../../resLoader.gif";

const Crossword = forwardRef((props, ref) => {
  const { data, btnColor, btnHilight, gameFinish, loader } = props;
  const ans = useRef();
  const reward = useRef();
  const [showLoading, setShowLoading] = useState(true);
  const [over, setOver] = useState(false);
  const [win] = useSound(Win, { volume: 0.25 });
  const [startTimer, setStartTimer] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [skipped, setSkipped] = useState(false);

  const deadline = data.timer;
  const handleTimeFinish = () => {
    console.log("finished");
  };

  const confetti = {
    type: "confetti",
    fakingRequest: false,
    angle: 90,
    decay: 0.91,
    spread: 45,
    startVelocity: 35,
    elementCount: 300,
    elementSize: 20,
    lifetime: 200,
    zIndex: 100,
    springAnimation: true,
  };

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 2000);
  }, []);
  const [showLoader, setShowLoader] = useState(false);

  const getReward = () => {
    reward.current.rewardMe();
    setTimeout(() => setStartTimer(false), 2000);
  };
  const getTimeLeft = async (sec) => {
    const score = parseInt(data.baseScore) + parseInt(data.multiplier) * sec;
    let sessionId = localStorage.getItem("sessionId");
    const dataToSend = {
      currentTime: sec,
      name: localStorage.getItem("teamName"),
      sessionId: localStorage.getItem("sessionId"),
      skipped: skipped,
      game_type: data.type,
      score: parseInt(score),
    };
    setTimeLeft(sec);
    setShowLoader(true);
    await axios
      .post(`${url}/api/score/${sessionId}`, dataToSend)
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          alert(res.data.error);
        }
      })
      .catch((err) => alert(err));
    setShowLoader(false);
    // gameFinish(dataToSend);
    setTimeLeft(sec);
    // console.log(sec);
  };

  const checkSubmit = (c) => {
    if (c && !over) {
      setOver(true);
      win();
      getReward();
    }
  };

  useImperativeHandle(ref, () => ({
    skipLevel() {
      setSkipped(true);
      ans.current.fillAllAnswers();
      setTimeout(() => {
        getReward();
      }, 500);
    },
  }));

  return (
    <div
      className="crossword-container"
      style={{
        backgroundImage: `url(${data.bgImg})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {!showLoading ? (
        <>
          <center>
            <img
              src={data.titleImg}
              alt="top-img"
              className="crossword-top-image"
            />
          </center>
          <QuestionHintButton
            btnColor={btnColor}
            btnHilight={btnHilight}
            hintImg={data.hintImg}
          />
          <Reward
            ref={(ref) => (reward.current = ref)}
            type={data.rewardType}
            config={{ ...confetti, emoji: data.emojiList }}
          >
            {showLoader ? (
              <img
                src={resLoader}
                style={{ position: "fixed", top: "45vh", left: "45vw" }}
                alt="img"
              />
            ) : (
              <div style={{ width: "80%", maxHeight: "80vh" }} ref={reward}>
                <ThemeProvider
                  theme={{
                    columnBreakpoint: "1000px",
                    gridBackground: "rgba(255,255,255,0)",
                    cellBackground: "#ffe",
                    cellBorder: "#fca",
                    numberColor: "black",
                  }}
                >
                  <CrosswordGame
                    data={data.data}
                    useStorage={false}
                    ref={ans}
                    onCrosswordCorrect={checkSubmit}
                  />
                </ThemeProvider>
              </div>
            )}
          </Reward>
          <div
            onClick={() => {
              checkSubmit();
            }}
          >
            <Timer
              time={deadline}
              handleFinish={handleTimeFinish}
              start={startTimer}
              getTimeLeft={getTimeLeft}
            />
          </div>
        </>
      ) : (
        <div
          style={{ display: "grid", placeItems: "center", paddingTop: "40vh" }}
        >
          <img src={loader} alt="loading..." />
        </div>
      )}
    </div>
  );
});

export default Crossword;

import React from "react";

import "./TopImage.css";
const TopImage = ({ imageUrl }) => {
  return (
    <center>
      <div className="topImage">
        <img src={imageUrl} alt="header" />
      </div>
    </center>
  );
};

export default TopImage;

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import YtVideo from "./Components/YtVideo/YtVideo";
import axios from "axios";
import bg from "./background.png";
import { url } from "./url";
const StartScreen = (props) => {
  const history = useHistory();
  let { sessionId } = useParams();
  const { data } = props;
  const handleClick = (props) => {
    axios
      .get(`${url}/api/start/${sessionId}`)
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error);
        } else {
          console.log("nice");
          history.push(`/${sessionId}/games`);
        }
      })
      .catch((err) => alert(err));
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100vh",
        backgroundImage: `url(${data.loginBackground})`,
        backgroundSize: "cover",
      }}
    >
      {/* <YtVideo url={"https://youtu.be/N16qyrhKEU8"} mediaType="yt" /> */}
      <Button
        onClick={handleClick}
        style={{
          fontFamily: `${data.btnFont}`,
          backgroundColor: `${data.btnColor}`,
          border: `1px solid ${data.btnHilight}`,
          color: `${data.btnHilight}`,
          borderRadius: "7px",
        }}
      >
        START THE GAME
      </Button>
    </div>
  );
};
export default StartScreen;

import React from "react";

import "./YtVideo.css";

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

const YtVideo = ({ url, mediaType }) => {
  const YtVideoUrl = `https://www.youtube.com/embed/${youtube_parser(url)}`;
  return mediaType === "yt" ? (
    <div className="YtVideo-conatiner">
      <iframe
        className="YtVideo"
        src={YtVideoUrl}
        frameborder="1"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="YtVideo"
      ></iframe>
    </div>
  ) : (
    <div className="img-container">
      <img src={url} alt="img" className="image-container" />
    </div>
  );
};

export default YtVideo;

import React from "react";
import { withRouter } from "react-router-dom";
import TopImage from "../Components/TopImage/TopImage";
import { Button } from "antd";

import "./EndPage.css";

const EndPage = (props) => {
  const { data } = props;
  return Object.keys(data).length > 1 ? (
    <div
      className="endpage"
      style={{
        backgroundImage: `url('${data.endBackground}')`,
        backgroundSize: "cover",
      }}
    >
      <TopImage imageUrl={data.endCreative} />

      {/* <div style={{ textAlign: "center" }}>
        <a href="https://storage.googleapis.com/eo-race.appspot.com/RaceToRebootRoute1/Food_Menu_For_Sundowner.pdf">
          <Button
            style={{
              fontFamily: `${data.btnFont}`,
              backgroundColor: `${data.btnHilight}`,
              border: `1px solid ${data.btnColor}`,
              color: `${data.btnColor}`,
              marginTop: "1rem",
              borderRadius: "10px",
            }}
            onClick=""
          >
            Download Menu
          </Button>
        </a>
      </div> */}
    </div>
  ) : null;
};

export default withRouter(EndPage);

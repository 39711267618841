import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Button } from "antd";
import "./UploadPic.css";
import TopImage from "../../Components/TopImage/TopImage";
import resLoader from "../../resLoader.gif";
import { useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../url";
import FileBase64 from "react-file-base64";
import YtVideo from "../../Components/YtVideo/YtVideo";

const UploadPic = forwardRef(
  ({ data, btnColor, btnHilight, gameFinish, stuck, loader }, ref) => {
    const [clickImage, setClickImage] = useState(false);
    let { sessionId } = useParams();
    const [position, setPosition] = useState({
      latidtude: "",
      longitude: "",
      accuracy: "",
    });
    const [refreshLocation, setRefreshLocation] = useState(false);
    const [skipped, setSkipped] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const uploadFile = useRef();

    useEffect(() => {
      setTimeout(() => setShowLoading(false), 2000);
    }, []);

    useImperativeHandle(ref, () => ({
      skipLevel() {
        setSkipped(true);
        setTimeout(async () => {
          const dataToSend = {
            name: localStorage.getItem("teamName"),
            sessionId: localStorage.getItem("sessionId"),
            skipped: true,
            game_type: data.type,
            id: data.id,
          };

          setShowLoader(true);
          await axios
            .post(`${url}/api/score/${sessionId}`, dataToSend)
            .then((res) => {
              console.log(res.data);
              if (res.data.error) {
                alert(res.data.error);
              }
            })
            .catch((err) => alert(err));
          setShowLoader(false);
        }, 500);

        // gameFinish();
      },
    }));

    useEffect(() => {
      // navigator.permissions
      //   .query({ name: "geolocation" })
      //   .then((res) => console.log(res));

      navigator.geolocation.getCurrentPosition(
        (position) => {
          let obj = {
            latidtude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          };
          setPosition(obj);
        },
        function error(msg) {
          alert("Please enable your GPS position feature.", msg);
        },
        { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      );
      setRefreshLocation(false);
    }, [refreshLocation]);

    const handleTakePhoto = async (dataUri) => {
      const imgData = dataUri.base64.substring(
        `data:${dataUri.type};base64,`.length
      );
      console.log(dataUri.type);
      // const data = dataUri

      setClickImage(false);
      const dataToSend = {
        name: localStorage.getItem("teamName"),
        sessionId: localStorage.getItem("sessionId"),
        skipped: skipped,
        game_type: data.type,
        lat: position.latidtude,
        lon: position.longitude,
        // lat: data.lat,
        // lon: data.lon,
        img: imgData,
        img_type: dataUri.type.split("/")[1],
        id: data.id,
      };

      setShowLoader(true);
      await axios
        .post(`${url}/api/score/${sessionId}`, dataToSend)
        .then((res) => {
          if (res.data.error) {
            alert(res.data.error);
          }
        })
        .catch((err) => alert(err));
      setShowLoader(false);
      // gameFinish(dataToSend);
    };

    return (
      <div
        style={{
          backgroundImage: `url('${data.bgImg}')`,
        }}
        className="upload-container"
      >
        {!stuck || !showLoading ? (
          <>
            {!clickImage ? (
              <>
                <TopImage imageUrl={data.titleImg} />
                {showLoader ? (
                  <img
                    src={resLoader}
                    style={{ position: "fixed", top: "45vh", left: "45vw" }}
                  />
                ) : null}
                {data.primaryMediaUrl ? (
                  <YtVideo
                    mediaType={data.primaryMediaType}
                    url={data.primaryMediaUrl}
                  />
                ) : null}
                <div
                  className="upload-btn-2"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div className="input-box2">
                    <FileBase64
                      multiple={false}
                      onDone={handleTakePhoto}
                      accept=".png"
                      className="inputfile inputfile-4"
                      id="file"
                    />
                  </div>
                  <div className="upload-location">
                    <span>Current Location</span>
                    <span>Latitude: {position.latidtude}</span>
                    <span>Longitude: {position.longitude}</span>
                  </div>
                  <Button
                    style={{
                      backgroundColor: `${btnColor}`,
                      border: `1px solid ${btnHilight}`,
                      color: `${btnHilight}`,
                    }}
                    onClick={() => setRefreshLocation(true)}
                  >
                    Recheck Location
                  </Button>
                </div>
              </>
            ) : (
              <Camera
                onTakePhotoAnimationDone={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
                idealFacingMode="FACING_MODES.USER"
                isMaxResolution={true}
                isFullscreen={false}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              paddingTop: "40vh",
            }}
          >
            <img src={loader} alt="loading..." />
          </div>
        )}
      </div>
    );
  }
);

export default UploadPic;

import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Timer = (props) => {
  const { time, handleFinish, start, getTimeLeft } = props;
  const [timeLeft, setTimeLeft] = React.useState();
  React.useEffect(() => {
    if (!start) {
      getTimeLeft(timeLeft);
    }
    // eslint-disable-next-line
  }, [start]);

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  return (
    <center>
      <CountdownCircleTimer
        isPlaying={start}
        duration={time}
        colors={[
          ["#004777", 0.33],
          ["#F7B801", 0.33],
          ["#A30000", 0.33],
        ]}
        renderAriaTime={(t) => setTimeLeft(t.remainingTime)}
        size={80}
        strokeWidth={4}
        onComplete={handleFinish}
      >
        {({ remainingTime }) => {
          return renderTime("seconds", remainingTime);
        }}
      </CountdownCircleTimer>
    </center>
  );
};

export default Timer;

import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import QuestionHintButton from "../../Components/HintButtons/QuestionHintButton";
import TopImage from "../../Components/TopImage/TopImage";
import useSound from "use-sound";
import Win from "../../win.mp3";
import Reward from "react-rewards";
import "./Quiz.css";
import axios from "axios";
import { url } from "../../url";
import { useParams } from "react-router-dom";
import { Button } from "antd";

const Quiz = forwardRef((props, ref) => {
  const { data, btnColor, btnHilight } = props;
  const [win] = useSound(Win, { volume: 0.25 });
  const reward = useRef();
  const { sessionId } = useParams();
  let ans = useRef();
  useEffect(() => {
    setTimeout(() => setShowLoading(false), 2000);
  }, []);

  useEffect(() => {
    // ol.current = document.querySelectorAll(".overlay-top");
    ans.current = document.querySelectorAll(".answers");
  });

  const [showLoading, setShowLoading] = useState(true);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [question, setQuestion] = useState(data.levels[0].question);
  const [answers, setAnswers] = useState(data.levels[0].answers);
  const [correctAnswer, setCorrectAnswer] = useState(
    data.levels[0].correctAnswer
  );
  const [wrongCount, setWrongCount] = useState(0);
  const [isOver, setIsOver] = useState(false);

  const getReward = () => {
    reward.current.rewardMe();
    // setStartTimer(false);
  };
  const getTimeLeft = async (sec) => {
    // gameFinish(dataToSend);
  };

  const handleClick = async (clickedAnswer, index) => {
    if (correctAnswer === clickedAnswer) {
      ans.current[index].classList.add("correct-answer");
      await setTimeout(() => {
        try {
          ans.current[index].classList.remove("correct-answer");
          setQuestionNumber(questionNumber + 1);
          setQuestion(data.levels[questionNumber + 1].question);
          setAnswers(data.levels[questionNumber + 1].answers);
          setCorrectAnswer(data.levels[questionNumber + 1].correctAnswer);
        } catch (e) { }
      }, 1000);

      if (questionNumber === data.levels.length - 1) {
        setIsOver(true);
        getReward();
        win();
        setTimeout(async () => {
          const dataToSend = {
            wrongCount: wrongCount,
            name: localStorage.getItem("teamName"),
            sessionId: localStorage.getItem("sessionId"),
            skipped: false,
            game_type: data.type,
            score: parseInt(
              parseInt(data.basePoints) * data.levels.length -
              wrongCount * parseInt(data.answerPenalty)
            ),
          };
          await axios
            .post(`${url}/api/score/${sessionId}`, dataToSend)
            .then((res) => {
              console.log(res.data);
              if (res.data.error) {
                alert(res.data.error);
              }
            })
            .catch((err) => alert(err));
        }, 4000);
      }
    } else {
      ans.current[index].classList.add("wrong-answer");
      setWrongCount((prevState) => prevState + 1);
      setTimeout(() => {
        try {
          ans.current[index].classList.remove("wrong-answer");
        } catch (e) { }
      }, 1000);
    }
  };
  const confetti = {
    type: "confetti",
    fakingRequest: false,
    angle: 90,
    decay: 0.91,
    spread: 45,
    startVelocity: 35,
    elementCount: 300,
    elementSize: 20,
    lifetime: 200,
    zIndex: 100,
    springAnimation: true,
  };

  useImperativeHandle(ref, () => ({
    skipLevel() {
      getReward();
      setTimeout(async () => {
        setIsOver(true);
        win();

        const dataToSend = {
          wrongCount: wrongCount,
          name: localStorage.getItem("teamName"),
          sessionId: localStorage.getItem("sessionId"),
          skipped: true,
          game_type: data.type,
          score: 0,
        };
        await axios
          .post(`${url}/api/score/${sessionId}`, dataToSend)
          .then((res) => {
            if (res.data.error) {
              alert(res.data.error);
            }
          })
          .catch((err) => alert(err));
      }, 4000);
    },
  }));

  return (
    <div
      className="quiz-container"
      style={{
        backgroundImage: `url('${data.bgImg}')`,
      }}
    >
      {!showLoading ? (
        <>
          <QuestionHintButton
            btnColor={btnColor}
            btnHilight={btnHilight}
            hintImg={data.hintImg}
          />
          <Reward
            ref={(r) => {
              reward.current = r;
              console.log(reward.current);
            }}
            type={data.rewardType}
            config={{ ...confetti, emoji: data.emojiList }}
          >
            <div style={{ textAlign: "center" }}>
              <TopImage imageUrl={data.titleImg} />
              {/* <Button
                style={{
                  backgroundColor: `${btnColor}`,
                  border: `1px solid ${btnHilight}`,
                  color: `${btnHilight}`,
                }}
                // onClick={handleSubmit}
              >
                Score :{" "}
                {parseInt(
                  parseInt(data.basePoints) * questionNumber -
                    wrongCount * parseInt(data.answerPenalty)
                )}
              </Button> */}
            </div>
          </Reward>
          {!isOver ? (
            <div
              className="question-container"
              style={{
                backgroundImage: `url('${data.optionsBackImg}')`,
              }}
              ref={reward}
            >
              <div className="questions-div">
                <div
                  className="question"
                  style={{ color: data.txtColor, fontFamily: data.txtFont }}
                >
                  Q {questionNumber + 1}: {question}
                </div>

                <div
                  className="answers answer-a"
                  style={{ color: data.txtColor, fontFamily: data.txtFont }}
                  onClick={() => handleClick(answers.a, 0)}
                >
                  {"A : " + answers.a}
                </div>
                <div
                  className="answers answer-b"
                  style={{ color: data.txtColor, fontFamily: data.txtFont }}
                  onClick={() => handleClick(answers.b, 1)}
                >
                  {"B : " + answers.b}
                </div>
                <div
                  className="answers answer-c"
                  style={{ color: data.txtColor, fontFamily: data.txtFont }}
                  onClick={() => handleClick(answers.c, 2)}
                >
                  {"C : " + answers.c}
                </div>
              </div>
            </div>
          ) : (
            <h3>Level Completed</h3>
          )}
        </>
      ) : (
        <div
          style={{ display: "grid", placeItems: "center", paddingTop: "40vh" }}
        >
          <img src={props.loader} alt="loading..." />
        </div>
      )}
    </div>
  );
});

export default Quiz;

import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import TopImage from "../../Components/TopImage/TopImage";
import { Button, Input } from "antd";
import useSound from "use-sound";
import Win from "../../win.mp3";
import Reward from "react-rewards";
import QuestionHintButton from "../../Components/HintButtons/QuestionHintButton";
import "./ClueGame.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import resLoader from "../../resLoader.gif";
import { url } from "../../url";
import { distance } from "../../stringDistance";

const ClueGame = forwardRef(
  ({ data, btnColor, btnHilight, gameFinish, stuck, loader }, ref) => {
    const [clueNumber, setClueNumber] = useState(0);
    const [gameEnd, setGameEnd] = useState(false);
    const [wrongCount, setWrongCount] = useState(0);
    const [answer, setAnswer] = useState("");
    const [win] = useSound(Win, { volume: 0.25 });
    const [skipped, setSkipped] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [msg, setMsg] = useState("");
    let similar;
    let answerPenalty = data.answerPenalty;

    let { sessionId } = useParams();
    const reward = useRef();
    const confetti = {
      type: "confetti",
      fakingRequest: false,
      angle: 90,
      decay: 0.91,
      spread: 45,
      startVelocity: 35,
      elementCount: 300,
      elementSize: 20,
      lifetime: 200,
      zIndex: 100,
      springAnimation: true,
    };

    useEffect(() => {
      setTimeout(() => setShowLoading(false), 4000);
    }, []);

    useImperativeHandle(ref, () => ({
      skipLevel() {
        setSkipped(true);
        setWrongCount(wrongCount - 1000);
        setAnswer(data.answer);
      },
    }));
    const getReward = () => {
      reward.current.rewardMe();
      setTimeout(() => sendData(), 3500);
    };

    const sendData = async () => {
      setShowLoader(true);
      const dataToSend = {
        name: localStorage.getItem("teamName"),
        sessionId: localStorage.getItem("sessionId"),
        skipped: skipped,
        game_type: data.type,
        type: data.type,
        score: wrongCount + 100,
      };
      await axios
        .post(`${url}/api/score/${sessionId}`, dataToSend)
        .then((res) => {
          console.log(res.data);
          if (res.data.error) {
            alert(res.data.error);
          }
        })
        .catch((err) => alert(err));
      setShowLoader(false);
    };

    const clueHandle = () => {
      if (clueNumber < 2) {
        setClueNumber(clueNumber + 1);
        setWrongCount(wrongCount - parseInt(data.cluePenalty));
      } else {
        setClueNumber(2);
        setSkipped(true);
        setWrongCount(wrongCount - parseInt(data.cluePenalty));
        setAnswer(data.answer);
      }
    };

    const handleSubmit = () => {
      setMsg("");
      similar = distance(data.answer.toLowerCase(), answer.toLowerCase());
      if (similar * 100 >= 90) {
        win();
        setTimeout(() => getReward(), 500);
      } else {
        setMsg("try again");
        setTimeout(() => setMsg(""), 1500);
        setAnswer("");
        setWrongCount(wrongCount - parseInt(data.answerPenalty));
      }
    };
    return (
      <div
        style={{
          backgroundImage: `url('${data.bgImg}')`,
        }}
        className="clue-game-main-container"
      >
        <QuestionHintButton
          btnColor={btnColor}
          btnHilight={btnHilight}
          hintImg={data.hintImg}
        />
        {showLoader ? (
          <img
            src={resLoader}
            style={{ position: "fixed", top: "45vh", left: "45vw" }}
          />
        ) : null}
        <TopImage imageUrl={data.titleImg} />
        {showLoader ? (
          <img
            src={resLoader}
            style={{ position: "fixed", top: "45vh", left: "45vw" }}
          />
        ) : null}
        {!stuck || !showLoading ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Reward
                ref={(ref) => (reward.current = ref)}
                type={data.rewardType}
                config={{ ...confetti, emoji: data.emojiList }}
              >
                {/* <Button
                  style={{
                    backgroundColor: `${btnColor}`,
                    border: `1px solid ${btnHilight}`,
                    color: `${btnHilight}`,
                  }}
                  // onClick={handleSubmit}
                >
                  Score : {wrongCount}
                </Button> */}
                <div className="hint-image" ref={reward}>
                  <img src={data.optionsImg[clueNumber]} alt="hint-img" />
                </div>
              </Reward>
            </div>

            <div className="input-container">
              <Input
                placeholder="Your Answer..."
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <div className="btn">
                <Button
                  style={{
                    backgroundColor: `${btnColor}`,
                    border: `1px solid ${btnHilight}`,
                    color: `${btnHilight}`,
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <Button
                  style={{
                    backgroundColor: `${btnColor}`,
                    border: `1px solid ${btnHilight}`,
                    color: `${btnHilight}`,
                  }}
                  onClick={clueHandle}
                >
                  Next Clue
                </Button>
              </div>
              <div>{msg}</div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              paddingTop: "40vh",
            }}
          >
            <img src={loader} alt="loading..." />
          </div>
        )}
      </div>
    );
  }
);

export default ClueGame;

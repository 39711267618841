import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import axios from "axios";
import "../../App.css";
import { url } from "../../url";
import { distance } from "../../stringDistance";
import { message } from "antd";
import resLoader from "../../resLoader.gif";
import ReactHowler from "react-howler";
import himg from "./headphone-symbol.png";
import Win from "../../win.mp3";
import useSound from "use-sound";

import { useParams } from "react-router-dom";
import { Button } from "antd";
import Reward from "react-rewards";

const confetti = {
  type: "confetti",
  fakingRequest: false,
  angle: 90,
  decay: 0.91,
  spread: 45,
  startVelocity: 35,
  elementCount: 300,
  elementSize: 20,
  lifetime: 200,
  zIndex: 100,
  springAnimation: true,
};

const AudioScreen = forwardRef(
  ({ data, btnColor, btnHilight, gameFinish, stuck, loader }, ref) => {
    let { sessionId } = useParams();
    const bgImg =
      "https://storage.googleapis.com/eo-race.appspot.com/24OCT/end.png";

    const [answer, setAnswer] = useState("");
    const [play, setPlay] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [score, setScore] = useState(0);
    const [showLoading, setShowLoading] = useState(true);
    const [skipped, setSkipped] = useState(false);
    const reward = useRef();
    useEffect(() => {
      setTimeout(() => setShowLoading(false), 4000);
    }, []);
    const [win] = useSound(Win, { volume: 0.25 });

    useImperativeHandle(ref, () => ({
      skipLevel() {
        win();
        getReward();
        setAnswer(data.answer);
        setSkipped(true);
        setShowLoader(true);
        setTimeout(async () => {
          let dataToSend = {
            name: localStorage.getItem("teamName"),
            sessionId: localStorage.getItem("sessionId"),
            game_type: data.type,
            score: parseInt(score),
          };
          await axios
            .post(`${url}/api/score/${sessionId}`, dataToSend)
            .then((res) => {
              console.log(res.data);
              if (res.data.error) {
                message.error(res.data.error);
              }
            })
            .catch((err) => message.error(err));
          setShowLoader(false);
        }, 3500);
      },
    }));

    const handleSubmit = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      if (showLoader) {
        return;
      }
      let similarity = distance(
        data.answer.toLowerCase(),
        answer.toLowerCase()
      );
      let sessionId = localStorage.getItem("sessionId");

      if (similarity * 100 >= 90) {
        message.success("correct answer !!!");
        win();
        let dataToSend = {
          name: localStorage.getItem("teamName"),
          sessionId: localStorage.getItem("sessionId"),
          game_type: data.type,
          score:
            parseInt(score) +
            parseInt(data.answerPoints ? data.answerPoints : 100),
        };
        getReward();
        setAnswer("");
        setTimeout(() => {
          axios
            .post(`${url}/api/score/${sessionId}`, dataToSend)
            .then((res) => {
              console.log(res.data);
              setShowLoader(false);
              if (res.data.error) {
                message.error(res.data.error);
              }
            })
            .catch((err) => message.error(err));
        }, 3500);
      } else {
        message.error("please try again");
        setAnswer("");
        setScore(
          (prevState) =>
            prevState - parseInt(data.wrongPenalty ? data.wrongPenalty : 10)
        );
        setShowLoader(false);
      }
    };

    const getReward = () => {
      reward.current.rewardMe();
    };
    return (
      <div
        className='backgroundImage'
        style={{
          backgroundImage: `url('${data.bgImg}')`,
        }}
      >
        {showLoader ? (
          <img
            src={resLoader}
            style={{ position: "fixed", top: "45vh", left: "45vw" }}
          />
        ) : null}

        {!showLoading ? (
          <>
            <ReactHowler
              src={data.audioFile}
              playing={play}
              preload={true}
              loop={true}
            />
            <div style={{ textAlign: "center" }}>
              <Reward
                ref={(ref) => (reward.current = ref)}
                type={data.rewardType}
                config={{ ...confetti, emoji: data.emojiList }}
              ></Reward>
              <div className='topImage'>
                <img src={data.titleImg} alt='' />
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{
                    backgroundColor: `${btnColor}`,
                    border: `1px solid ${btnHilight}`,
                    color: `${btnHilight}`,
                  }}
                  // onClick={handleSubmit}
                >
                  {play ? "Playing" : "Paused"}
                </Button>
                <Button
                  style={{
                    backgroundColor: `${btnColor}`,
                    border: `1px solid ${btnHilight}`,
                    color: `${btnHilight}`,
                  }}
                  // onClick={handleSubmit}
                >
                  Score : {score}
                </Button>
              </div>
              <div
                className='clueImageContainer'
                style={{
                  backgroundImage: `url('${data.audioBackgroundImg}')`,
                }}
              >
                <div className='clueImage' onClick={() => setPlay(!play)}>
                  <img
                    style={{
                      height: "15vh",
                      width: "auto",
                      marginTop: "20vh",
                    }}
                    src={himg}
                    alt=''
                  />
                </div>
                <form className='clueInput' onSubmit={handleSubmit}>
                  <input
                    type='text'
                    placeholder='Enter Answer :'
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <button type='submit' onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              paddingTop: "40vh",
            }}
          >
            <img src={loader} alt='loading...' />
          </div>
        )}
      </div>
    );
  }
);

export default AudioScreen;

import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

const QuestionHintButton = (props) => {
  const { btnColor, btnHilight, hintImg } = props;

  const text = (
    <img alt="hint" src={hintImg} style={{ width: "100%", height: "auto" }} />
  );
  return (
    <Tooltip placement="leftTop" title={text} color={"white"}>
      <QuestionCircleFilled
        style={{
          color: `${btnHilight}`,
          backgroundColor: `${btnColor}`,
          border: `1px solid ${btnHilight}`,
          borderRadius: "50%",
          fontSize: "1.7rem",
          position: "fixed",
          right: "10px",
          top: "10px",
        }}
      />
    </Tooltip>
  );
};

export default QuestionHintButton;
